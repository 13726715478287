@import './style.shared';

.footer--content {
  padding: 160px 4.6%;

  &-action {
    margin: 24px 0 50px;
  }
}

@media (width <= 1200px) {
  .footer {
    background-position: center;
  }

  .footer--content {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (width <= 1024px) {
  .footer {
    background-position: center;
  }

  .footer--content {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (width <= 600px) {
  .footer {
    background-position: center bottom;
  }

  .footer--content {
    margin-left: 24px;
    margin-right: 24px;
  }
}
