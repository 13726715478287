@import '~@andes/progress-indicator-circular/index';
@import '~@andes/modal/index';

.form--container {
  position: relative;

  svg + .andes-button__text {
    font-size: 14px;
  }

  &--header {
    &_button {
      padding: 0;

      svg {
        width: 13px;
      }
    }

    &_title {
      color: $andes-gray-550-solid;
    }

    &_subtitle {
      margin: 0 0 24px;
      font-weight: 600;
      color: $andes-gray-550-solid;
    }
  }

  &_content {
    background-color: $andes-white;
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
    border-radius: 6px;

    h3 {
      font-weight: 600;
    }

    .andes-form-control.textfield {
      padding-top: 0;
      height: 101px;
    }

    .andes-form-control.textfield.heigth {
      height: 80px;
    }

    .andes-form-control.dropdown {
      height: 75px;
    }

    .andes-form-control--textfield .andes-form-control__label {
      white-space: normal;
    }
  }

  &--footer {
    &_check-terms {
      a {
        color: $andes-blue-500;
        font-weight: 600;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 80%;
    background-color: rgb(0 0 0 / 50%);
    border-radius: 6px;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
}

.congrats--container {
  padding: 40px 20px 500px;

  &_info {
    background-color: $andes-white;
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
    border-radius: 6px;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .congrats--content {
    &-title {
      font-weight: 600;
      margin-bottom: 8px;

      &-error {
        margin: 63px 0 0;
      }
    }

    &-details {
      font-size: 14px;
      line-height: 18px;
      max-width: 336px;
      margin: 0;

      &-error {
        margin: 8px 0 18px;
      }
    }
  }
}

.congrats--image {
  max-width: 237.39px;
}

.modal {
  .andes-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    padding-top: 0;
  }

  &-img {
    text-align: center;
  }

  &-title {
    font-weight: 600;
    text-align: center;
    margin: 32px 0 12px;
  }

  &-description {
    text-align: center;
    margin: 0 0 35px;
  }

  .full-width {
    width: calc(100% - 8px);
  }

  .button2 {
    margin-top: 8px;
    padding: 14px;
  }

  .andes-button__content {
    line-height: 18px;
  }
}

.w-100 {
  width: 100%;
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger {
  width: 100%;
  min-height: 22px;
}
