@import './style.shared';

.header-hero {
  padding: 60px 4.6% 0;
  height: 700px;

  @media screen and (width <= 800px) {
    height: 1200px;
  }

  @media screen and (width <= 700px) {
    height: 1620px;
  }

  @media screen and (width <= 500px) {
    height: 1200px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1050px;

  &--content {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    margin: 0;

    &-action {
      max-width: 330px;
    }
  }

  &--image {
    max-height: 400px;
  }

  @media (width <= 1023px) {
    &--content {
      &-title {
        font-size: 32px;
        line-height: 30px;
      }

      &-details {
        font-size: 20px;
        line-height: 22px;
      }
    }

    &--image {
      width: 420px;
    }
  }

  @media (width <= 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--content {
      align-items: center;
      text-align: center;
      max-width: 80%;
      margin-bottom: 30px;

      &-title {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 16px;
      }

      &-details {
        font-size: 18px;
        line-height: 20px;
      }

      &-action {
        font-size: 14px;
      }
    }

    &--image {
      width: 302px;
    }
  }

  @media (width <= 500px) {
    &--content {
      max-width: 80%;
    }
  }
}
