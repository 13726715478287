@import './style.shared';

.success-cases-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 4.6%;
}

.success-cases {
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;

  &-title {
    font-size: 28px;
    line-height: 35px;
    margin: auto;
    margin-bottom: 40px;
    max-width: 58%;
  }

  @media (width <= 1023px) {
    &-title {
      font-size: 24px;
      line-height: 25px;
    }
  }

  @media (width <= 800px) {
    &-title {
      max-width: 100%;
    }
  }
}

.success-cases--card {
  &-title {
    &-info {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-description {
    font-size: 16px;
    line-height: 20px;
    height: 80px;
    overflow-y: auto;
    margin: 0;
    margin-bottom: 32px;
  }

  &-author {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0;
    min-height: 22px;
  }

  @media (width <= 1023px) {
    &-description {
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
      text-align: center;
    }
  }

  @media (width <= 600px) {
    .success-cases--content {
      padding: 32px;
      display: flex;
      flex-direction: column;
    }
  }
}
