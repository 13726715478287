@import './style.shared';

.levels-container {
  padding: 60px 4.6%;

  @media (width <= 1023px) {
    padding: 60px;

    .levels--image {
      width: 320px;
    }
  }
}

.levels {
  max-width: 1050px;
  justify-content: space-between;

  &--content {
    max-width: 45%;

    &-title {
      font-size: 28px;
      margin-bottom: 8px;
      font-weight: 700;
      line-height: 31.5px;
    }

    &-details {
      color: $andes-gray-550;
      margin: 0;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }
}

@media (width <= 800px) {
  .levels {
    flex-direction: column-reverse;

    &--content {
      max-width: 90%;
      text-align: center;
      margin-bottom: 32px;
    }
  }
}

@media (width <= 500px) {
  .levels-container {
    padding: 60px 20px;

    .levels--content {
      max-width: 100%;
      text-align: center;
      margin-bottom: 32px;
    }

    .levels--image {
      width: 300px;
    }
  }
}
