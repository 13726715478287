@import '../../../../styles/common';
@import '../../../../styles/variables';

// Components
@import '../../../../components/agencies/HeaderHero/style.desktop';
@import '../../../../components/agencies/Footer/style.desktop';
@import '../../../../components/agencies/MembershipForm/style.desktop';
@import '../../../../components/agencies/Home/style.desktop';
@import '../../../../components/agencies/Home/ClientBenefits/style.desktop';
@import '../../../../components/agencies/Home/SuccessCases/style.desktop';
@import '../../../../components/agencies/Home/Levels/style.desktop';
@import '../../../../components/agencies/Home/Steps/style.desktop';
@import '../../../../components/agencies/Home/Events/style.desktop';
@import '../../../../components/agencies/Home/PartnersBenefits/style.desktop';
@import '../../../../components/agencies/Home/FAQ/style.desktop';
@import '../../../../components/agencies/Home/PaymentSolution/style.desktop';
@import '../../../../components/agencies/PreFooter/style.desktop';

.home {
  background-color: $andes-white;
}
