.clients--benefits-container {
  position: relative;

  //background-image: url('/app/assets/images/agencies/client/banner.svg');
  background-size: cover;
  padding: 120px 4.6%;

  &-title {
    color: $andes-white;
    font-size: 28px;
    font-weight: 700;
    line-height: 31.5px;
  }

  .clients--benefits {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1050px;

    &--content-container {
      display: flex;

      &-title {
        margin: 0;
        margin-bottom: 4px;
        font-weight: 700;
        color: $andes-white;
        margin-left: 15px;
      }

      &-details {
        margin: 0;
        margin-bottom: 16px;
        margin-left: 15px;
        color: $andes-white;
        text-align: left;

        .benefit--link {
          color: $andes-blue-500;
        }
      }
    }
  }
}
