.payment-solution {
  position: relative;
  margin: auto;

  &--container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      color: $andes-brand-text;
      font-size: 28px;
      font-weight: 700;
      line-height: 31.5px;
      text-align: center;
      max-width: 791px;
      margin: 32px 0 40px;
    }

    &-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
  }
}

.payment-solution__card {
  width: 100%;
  max-width: 313px;
  text-align: center;
  border-radius: 18px;
  gap: 24px;
  box-shadow: 0 6px 16px 0 #0000001a;

  &--content {
    &-title {
      margin: 12px 0 4px;
      font-size: 20px;
      font-weight: 700;
      line-height: 22.5px;
      color: $andes-gray-900-solid;
    }

    &-description {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
      color: $andes-gray-550-solid;
    }
  }
}
