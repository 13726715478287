@import './style.shared';

.events-container {
  padding: 60px 4.6%;

  @media (width <=1023px) {
    padding: 60px 32px 0;
  }
}

.events {
  max-width: 1050px;
  align-items: center;
  justify-content: space-between;

  img {
    border-radius: 40px;
  }

  &--image {
    max-width: 489px;
  }

  &--content {
    max-width: 49%;

    &-title {
      font-size: 28px;
      margin-bottom: 24px;
      font-weight: 700;
      line-height: 31.5px;
    }

    &-details {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

@media (width <=1023px) {
  .events {
    &--content {
      &-title {
        font-size: 22px;
        line-height: 20px;
      }

      &-details {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &--image {
      width: 390px;
    }
  }
}

@media (width <=800px) {
  .events {
    flex-direction: column;

    &--content {
      max-width: 80%;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}

@media (width <=500px) {
  .events-container {
    padding: 60px 20px 0;
  }

  .events--content {
    max-width: 100%;
  }

  .events--image {
    width: 320px;
  }
}
