@import './style.shared';

.step-certification {
  &-main-container {
    padding: 60px 4.6%;
    scroll-margin-top: 90px;
  }

  &-container {
    max-width: 998px;
  }

  &-header {
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 40px;
  }
}

.container-lines {
  width: 80%;
}

.container-text {
  margin-top: 16px;
  max-width: 100%;
  justify-content: space-between;

  .text-box {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    width: 25%;
    padding: 0 1.6%;
    margin: 0;
  }
}

.step-action {
  margin-top: 40px;
}

@media (width <=899px) {
  .step-certification-header {
    font-size: 24px;
    line-height: 28px;
    padding: 0 52px;
  }

  .text-box {
    font-size: 14px;
  }
}

@media (width <=899px) {
  .container-lines {
    img {
      width: 50px;
    }
  }

  .container-text {
    justify-content: center;

    .text-box {
      width: 22%;
      padding: 0 1%;
    }
  }
}
