.footer {
  position: relative;
  background-size: cover;
  display: flex;
  text-align: center;
  justify-content: center;

  &--content {
    position: relative;
    z-index: 2;
    max-width: 540px;
    padding: 160px 303px;

    &-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 40.5px;
      text-align: center;
      color: $andes-brand-text;
    }

    &-details {
      margin: 0;
      font-family: "Proxima Nova";
      font-size: 20px;
      font-weight: 600;
      line-height: 22.5px;
      text-align: center;
      color: $andes-brand-text;
    }

    &-action {
      max-width: 330px;
    }

    &-action.andes-button {
      background-color: var(
        --andes-custom-button-background-color,
        $andes-white
      );
      color: var(--andes-custom-button-text-color);
      width: auto;
    }

    .icon-container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: transparent;
      border: none;

      svg {
        max-width: unset;
        max-height: unset;
      }
    }
  }

  .andes-button--transparent:hover {
    background-color: $andes-white;
    color: $andes-gray-900-solid;
  }
}

.gray {
  background-color: $andes-gray-040-solid;
}

.white {
  background-color: $andes-white;
}
