@import './style.shared';

.faq {
  padding: 60px 4.6%;

  &--title-container {
    margin-bottom: 40px;
  }

  &--title {
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 33.75px;
    color: $andes-gray-900-solid;
  }

  &--title--margen {
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 33.75px;
    color: $andes-gray-900-solid;
  }
}

.faq--item-container {
  padding: 24px 40px 24px 24px;
  max-width: 998px;
}

.faq-item {
  &--title {
    font-size: 18px;
    font-weight: 700;
    line-height: 20.25px;
  }

  &--response {
    font-size: 16px;
    line-height: 20px;
    padding-right: 24px;
  }
}
