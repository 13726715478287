.events {
  display: flex;
  margin: auto;

  &--content {
    &-title {
      margin: 0;
      color: $andes-gray-900-solid;
      font-weight: 600;
    }

    &-details {
      color: $andes-gray-550-solid;
      margin: 0;
    }
  }
}
