@import './style.shared';

.prefooter {
  padding: 12px 35px;
  max-width: 998px;

  &--disclaimer {
    font-size: 12px;
    line-height: 15px;
  }
}
