.step-certification-main-container {
  background-color: $andes-gray-040-solid;
}

.step-certification {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &-body {
      display: flex;
      flex-direction: column;
      place-items: center;
    }
  }

  &-img {
    text-align: center;
    margin-bottom: 32px;
  }

  &-header {
    text-align: center;
    font-weight: 600;
    color: $andes-gray-900-solid;
  }
}

.container-lines {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .line {
    height: 1.5px;
    width: 40%;
    background-color: $andes-blue-500;
    margin: 0;
  }
}

.container-text {
  display: flex;

  .text-box {
    color: $andes-gray-550-solid;
  }
}
