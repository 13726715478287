.partners-benefits {
  &--container {
    text-align: center;
    margin: auto;
    max-width: 1050px;

    &-title {
      color: $andes-gray-900-solid;
      font-weight: 600;
      margin: 0;
    }

    &-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: -62px;
    }

    &-disclaimer {
      font-size: 12px;
      line-height: 15px;
      color: $andes-gray-550-solid;
      margin: 0;
    }

    &-img {
      margin-bottom: 32px;
    }
  }

  .benefit--link {
    color: $andes-blue-500;
  }
}

.partners-benefits__card {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--content {
    &-title {
      font-weight: 600;
      color: $andes-gray-900-solid;
      margin: 0;
    }

    &-description {
      color: $andes-gray-550-solid;
      margin: 0;
    }
  }
}
