.header-hero {
  position: relative;

  //background-image: url('/app/assets/images/agencies/banner.svg');
  background-size: cover;
  background-position: center bottom;
  height: 600px;
}

.header {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: auto;

  &--content {
    &-program {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
      color: $andes-brand-text;
      letter-spacing: 0.05em;
    }

    &-title {
      margin: 0;
      color: $andes-brand-text;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 800;
      line-height: 40px;

      &--rebrand {
        text-transform: none;
        font-weight: 700;
      }
    }

    &-details {
      color: $andes-brand-text;
      margin: 0;
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    &-action.andes-button {
      background-color: var(
        --andes-custom-button-background-color,
        $andes-white
      );
      color: var(--andes-custom-button-text-color);
    }
  }

  .andes-button--transparent:hover {
    background-color: $andes-white;
    color: $andes-gray-900-solid;
  }
}
