.success-cases {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.success-cases-img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 32px;
}

.success-cases-title {
  color: $andes-gray-900-solid;
  text-align: center;
  font-weight: 600;
  margin: 0;
}

.andes-carousel-snapped__pagination--light
  .andes-carousel-snapped__pagination-item
  button {
  border: 1px solid $andes-blue-500;
  background-color: $andes-white;
}

.andes-carousel-snapped__pagination--light
  .andes-carousel-snapped__pagination-item--active
  button {
  background-color: $andes-blue-500;
}

.andes-carousel-snapped__control {
  background-color: $andes-white;
  box-shadow: 0 1px 2px 0 #0000001f;
}

.andes-carousel-snapped__control:focus-visible {
  box-shadow: 0 0 0 2px $andes-white, 0 0 0 3px #007eb5,
    0 0 0 5px rgb(71 154 209 / 30%);
  outline: none;
}

.success-cases--card {
  margin: 2px;
  max-width: 484px;
  border-radius: 18px;

  .success-cases--content {
    padding: 60px;
    display: flex;
    flex-direction: column;
  }

  &-title {
    display: flex;
    align-items: center;
    flex-direction: row;

    &-info {
      font-weight: 600;
      margin: 0;
    }

    img {
      border-radius: 22px;
      width: 32px;
      height: 32px;
      margin-right: 14px;
    }
  }

  .success-cases--card-video {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    iframe {
      border-radius: 18px;
      border: none;
      width: 100%;
    }

    img {
      border-radius: 22px;
      width: 32px;
      height: 32px;
      margin-right: 14px;
    }
  }

  &-description {
    color: $andes-gray-550-solid;
  }

  &-author {
    font-weight: 600;
    color: $andes-gray-550-solid;
  }

  &-button {
    padding: 0;
    height: 16px;
    font-size: 14px;
    margin-left: 8px;
  }

  &-video {
    p {
      margin-bottom: 0;
    }

    img {
      border-radius: 6px;
      max-width: 400px;
    }
  }

  .andes-card--animated:hover {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
  }
}
