@import './style.shared';

$breakpoint-medium: 1023px;
$breakpoint-small-800: 800px;
$breakpoint-small: 500px;

.clients--benefits-container {
  margin: auto;

  &-action {
    width: 290px;
    align-self: center;
    margin-top: 50px;
  }

  &-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 31.5px;
    margin: 0;
    margin-bottom: 24px;
  }

  @media (max-width: $breakpoint-small) {
    padding: 0 20px 100px;

    &-title {
      max-width: 100%;
    }
  }
}

.clients--benefits {
  display: flex;
  justify-content: space-between;

  &--image {
    max-width: 418px;
    width: 100%;
  }

  &--content {
    max-width: 50%;

    &-container-title {
      font-size: 18px;
      line-height: 20.3px;
      margin-left: 15px;
      margin-bottom: 8px;
    }

    &-container-details {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  @media (max-width: $breakpoint-medium) {
    &--content {
      max-width: 80%;

      &-container {
        &-title {
          font-size: 18px;
          line-height: 22px;
        }

        &-details {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: $breakpoint-small-800) {
    flex-direction: column;

    &--content {
      max-width: 80%;

      &--image {
        width: 300px;
      }
    }
  }

  @media (max-width: $breakpoint-small) {
    &--content {
      max-width: 100%;
    }

    &--image {
      width: 300px;
    }
  }
}
