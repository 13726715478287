@import './style.shared';

.form--container {
  padding: 40px;
  margin: auto;
  max-width: 795px;

  &--header {
    &_title {
      margin: 30px 0 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    &_subtitle {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &_content {
    padding: 32px;
    padding-bottom: 8px;

    h3 {
      font-size: 20px;
      line-height: 25px;
      margin: 0 0 32px;
    }

    &_fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .andes-form-control.dropdown {
      padding-bottom: 10px;
    }

    .andes-form-control {
      width: 48%;
    }

    .andes-form-control.full-width {
      width: 100%;
    }
  }

  &--footer {
    padding: 30px 0 211px;

    .andes-checkbox,
    .recaptcha-container {
      margin-bottom: 30px;
      max-width: 680px;
    }

    &_check-terms {
      line-height: 20px;
    }

    &_btn {
      width: 270px;
    }
  }

  @media (width <= 680px) {
    &_content {
      padding: 20px;

      .andes-form-control {
        width: 100%;
      }
    }
  }
}

.congrats--container {
  max-width: 795px;
  margin: auto;

  &_info {
    margin-top: 20px;
    padding: 60px 0;
  }

  .congrats--content {
    &-title {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.modal {
  .andes-modal {
    max-width: 480px !important;
  }

  .andes-modal--small .andes-modal__header {
    padding: 2em;
    padding-bottom: 0;
  }

  &-img {
    width: 249px;
  }

  &-title {
    font-size: 24px;
    line-height: 30px;
  }

  &-description {
    line-height: 20px;
  }
}

.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger {
  width: 97%;
  min-height: 22px;
}

.andes-floating-menu--search {
  width: 98%;
  align-items: center;
  height: 25px;
}
