@import './style.shared';

.partners-benefits {
  padding: 60px 4.6% 40px;

  &--container {
    max-width: 1086px;
    margin-bottom: 100px;

    &-title {
      font-size: 28px;
      line-height: 35px;
      max-width: 64%;
      margin: auto;
      padding-right: 3.1%;
    }

    &-img {
      margin-bottom: 32px;
    }

    &-section {
      margin-top: 64px;
    }

    &-disclaimer {
      margin-top: 24px;
    }
  }

  .xxextra-large-width {
    width: 246px;
  }

  .xextra-large-width {
    width: 262px;
  }

  .extra-large-width {
    width: 241px;
  }

  .large-width {
    width: 231px;
  }

  .medium-width {
    width: 226px;
  }

  .small-width {
    width: 224px;
  }

  .extra-small-width {
    width: 222px;
  }

  .xextra-small-width {
    width: 235px;
  }

  @media (width <= 1023px) {
    &--container-title {
      font-size: 24px;
      line-height: 28px;
      max-width: 90%;
    }
  }

  @media (width <= 700px) {
    padding: 100px 80px 10px;
  }

  @media (width <= 500px) {
    padding: 60px 20px 10px;

    &--container {
      &-title {
        padding-right: 0;
      }
    }
  }
}

.partners-benefits__card {
  margin: 0 3.1% 60px 0;

  &--content {
    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      margin: 12px 0 4px;
    }

    &-description {
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }

  .partners-benefits__card-img svg,
  img {
    width: 24px;
    height: 24px;
  }

  .big-img {
    height: 74px;
  }

  @media (width <= 1023px) {
    margin: 0 6.6% 60px 0;
  }

  @media (width <= 485px) {
    margin: 0 0 60px;
  }
}
