@import './style.shared';

.payment-solution {
  scroll-margin-top: 156px;
  scroll-behavior: smooth;
  padding: 60px 4.6% 0;
  margin-top: -20%;

  &--container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 31.5px;
      text-align: center;
      width: 100%;
      max-width: 791px;
      margin: 20px 0 40px;
    }

    &-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
  }

  @media (width >= 1920px) {
    margin-top: -290px;
  }

  @media (width <= 1250px) {
    margin-top: -25%;
  }

  @media (width <= 1023px) {
    margin-top: -33%;

    &--container-title {
      font-size: 24px;
      line-height: 28px;
      max-width: 90%;
    }
  }

  @media (width <= 800px) {
    margin-top: -93%;
  }

  @media (width <= 700px) {
    margin-top: -163%;
  }

  @media (width <= 600px) {
    margin-top: -197%;
  }

  @media (width <= 500px) {
    margin-top: -123%;
  }
}

.payment-solution__card {
  width: 313px;
  text-align: center;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);

  &--content {
    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 22.5px;
      color: $andes-gray-900-solid;
    }

    &-description {
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
      color: $andes-gray-550-solid;
      margin: 0;
    }
  }

  @media (width <= 500px) {
    max-width: 292px;

    &--content {
      &-description {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
